import {barba__nextQSA} from '../_barba/_util.js';

init()

barba.hooks.enter((data) => {
    init(data);
});

function init(data) {
    const section = barba__nextQSA('.masonry__grid', data);
    if(!section) return;

    section.forEach(element => {
        const 
            comments = element.querySelectorAll('.pledge__comment-toggle'),
            moreBtn = element.querySelectorAll('[data-line-clamp] > button'),
            options = {
                container: element,
                columns: 2,
                margin: {
                    x: 0,
                    y: 30 
                }
            }

            element.macyInstance = Macy(options)
            element.macyInstance.reInit()
            element.macyInstance.recalculate(1)

        comments.forEach(ele => {
            ele.onchange = () => { 
                element.macyInstance.reInit();
            };
        });

        moreBtn.forEach(ele => {
            ele.addEventListener('click', ()=> { element.macyInstance.reInit() });
        });
       
    })
}