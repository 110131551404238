import {barba__nextQS} from '../_barba/_util.js';

const name = 'notification';
const options = {
    expires: 1,
    path: '/',
    domain: window.location.hostname,
}

init()

barba.hooks.enter((data) => {
    init(data);
});

barba.hooks.beforeLeave((data) => {
    kill();
});

function init(data) {
    const 
        popup = document.querySelector('.' + name),
        login = barba__nextQS('.login', data);
    if(!popup || login) return;
    if(Cookies.get(name) !== undefined) return;

    const 
        tl = gsap.timeline({paused: 1}),
        card = popup.querySelector('.' + name + '__card')

    tl
        .set(popup, { display: 'block', autoAlpha: 0})
        .set(card, { autoAlpha: 0, xPercent: -50, yPercent: -40})
        .to(popup, { autoAlpha: 1})
        .to(card, { autoAlpha: 1, yPercent: -50})

        popup.animation = tl;

    if (Cookies.get(name) == undefined) {
        setTimeout(() => { popup.animation.play(); }, 2000);
    }

    popup.querySelectorAll('.' + name + '__close').forEach(ele => {
        ele.onclick = e => {
            e.preventDefault();
            Cookies.set(name, 1, options);
            popup.animation.reverse();
        };
    })
}

function kill() {
    let popup = document.querySelector('.notification');
    if(popup == null || !popup.hasOwnProperty('animation')) return;
    popup.animation.reverse();
}