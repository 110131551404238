import {barba__nextQSA} from '../_barba/_util.js';

init()

barba.hooks.enter((data) => {
    init(data);
});

function init(data = '') {
    const items = barba__nextQSA('[data-line-clamp]', data);

    items.forEach(item => {
        const div = document.createElement('div');
        const html = item.innerHTML;
        const itemHeight = item.offsetHeight;
        const moreBtn = document.createElement('button');

        item.innerHTML = '';
        div.innerHTML = html;
        item.append(div);

        if(itemHeight > div.offsetHeight) {
            moreBtn.classList.add('text-link');
            moreBtn.classList.add('mt-1');
            item.append(moreBtn);
            moreBtn.onclick = ()=> {
                item.classList.toggle('active');
            }
        }
       
    });
}