export function init(swiper) {
    console.log('init query');
}

export function update(swiper) {
    const 
        id = swiper.slides[swiper.activeIndex].dataset.id,
        url = new URL(window.location.href)

    url.searchParams.set('id', id);

    // update url
    if (history.replaceState) {
        window.history.replaceState({path:url.href},'',url.href);
    }
}