import {barba__nextQSA} from '../_barba/_util.js';

init()

barba.hooks.enter((data) => {
    init(data);
});

function init(data) {
    const videos = barba__nextQSA('.card__video video', data);

    videos.forEach((element, i) => {
        element.onplay = ()=> {
            videos.forEach((ele, ii) => {
                if(i !== ii) ele.pause();
            });
        }
    })
}

