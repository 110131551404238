import {create} from './_create.js';
import {transitions} from './_transitions.js';
import {hooks} from './_hooks.js';

init();

function init() {
    if(window.barba === undefined) return;
    const container = create();

    // remove scrollRestoration
    if (history.scrollRestoration) history.scrollRestoration = 'manual';

    barba.init({
        debug: true,
        sync: false,
        timeout: 8000,
        transitions: transitions(container),
    })

    hooks();
}