export function initCarousel(section) {
    const 
        swiper = section.querySelector('.swiper').swiper,
        comment = {
            input: section.querySelector('#gallery__items-input'),
            items: section.querySelectorAll('.gallery__item'),
            discard: section.querySelectorAll('.gallery__item-form .text-link-secondary[for="gallery__item-form-input_1"]'),
        } 

    if(comment.items.length) gsap.set(comment.items, {display: 'none'});

    setSlideOverlay();

    comment.input.addEventListener("change", ()=> {
        let id = section.querySelector('.swiper .swiper-slide-active').dataset.id;
        let relComment = section.querySelector('.gallery__item[data-id="' + id +'"]')
        let checked = comment.input.checked
        let url = new URL(window.location.href)
        
        if(checked) {
            gsap.set(relComment, { display: 'block'})
            closeSlideOverlay()
        }

        if(!checked) {
            gsap.set(relComment, { display: 'none'})
        }
        url.searchParams.set('overlay', checked ? 1 : 0);

        // update url
        if (history.replaceState) {
            window.history.replaceState({path:url.href},'',url.href);
        }
    });

    comment.discard.forEach(ele => {
        ele.onclick= ()=> {
            let textarea = ele.closest('.gallery__item-form').querySelector('textarea')
            if(textarea !== null) textarea.value = '';
        }
    });

    swiper.slides.forEach(ele => {
        let img = ele.querySelector('img')
        let overlay = ele.querySelector('.gallery__slide-img')

        if(img !== null && overlay !== null) {
            img.onclick = ()=> {
                overlay.classList.toggle('active');
            }
        }

    });

    swiper.on('slideChange', function (swiper) {
        closeSlideOverlay()
    });

    function closeSlideOverlay() {
        let overlay = document.querySelector('.gallery__slide-img.active')
        if(overlay !== null) overlay.classList.remove('active');
    }

    function setSlideOverlay() {
        const url = new URL(window.location.href);
        const search = new URLSearchParams(url.search);
        let valid = false;

        if(search.has('overlay') && search.get('overlay') == 1) {
            if( search.has('id') && section.querySelector('.gallery__item[data-id="' + search.get('id') + '"]')) {
                let relComment = section.querySelector('.gallery__item[data-id="' + search.get('id') + '"]');
                comment.input.checked = true;
                gsap.set(relComment, { display: 'block'})
            }
        }
    }
}