export function initKeys(section) {
    const swiperEle = section.querySelector('.swiper');
    const overlayItems = section.querySelectorAll('.gallery__item');
    const formInput = document.getElementById('gallery__form-input');
    const overlayInput = document.getElementById('gallery__items-input');

    let isWorking = false;
    
    if(swiperEle && swiperEle.hasOwnProperty('swiper') && swiperEle.classList.contains('swiper-initialized')) {
        document.onkeydown = e => {
            e = e || window.event;
            keysHorizontal(e)
            keysVertical(e)
        }
    }

    barba.hooks.beforeLeave((data) => {
        document.onkeydown = null;
    })  
    
    function keysHorizontal(e) { 
        if(formInput.checked || overlayInput.checked) return;
                            
        if(e.key === 'ArrowLeft') return swiperEle.swiper.slidePrev();
        if(e.key === 'ArrowRight') return swiperEle.swiper.slideNext();
    }

    function keysVertical(e) {
        const active = section.querySelector('.swiper-slide-active');
        if(active.dataset.swiperSlideIndex == 0 || isWorking) return;

        

        if(e.key === 'ArrowUp') {
            let id = active.dataset.id
            let relComment = document.querySelector('.gallery__item[data-id="' + id +'"]')        
            
            gsap.set(relComment, { display: 'block'})
            overlayInput.checked = true;
            
            return working();
        }

        if(e.key === 'ArrowDown') {
            overlayInput.checked = false;
            gsap.set(overlayItems, { display: 'none'})
            
            return working();
        }
    }

    function working() {
        // isWorking = true;
        // setTimeout(()=> {
        //     isWorking = false;
        // }, 400);
    }
}



