export function barba__nextQS(selector, data) {
    const dom = (typeof data === 'object' && 'next' in data ? data.next.container : document);
    const qs = dom.querySelector(selector);
    return qs !== null ? qs : false;
}

export function barba__nextQSA(selector, data) {
    const dom = (typeof data === 'object' && 'next' in data ? data.next.container : document);
    const qs = [...dom.querySelectorAll(selector)]
    return qs !== null ? qs : false;
}

export function barba__scrollPosition(data) {
    const scroll = {
        x: 0,
        y: 0,
    }

    if(data.next.url.hash) {
        console.log('update scroll object')
    }

    window.scrollTo(scroll.x, scroll.y);
}