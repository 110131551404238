import {barba__nextQS} from '../_barba/_util.js';
import * as gsapGlobal from '../_gsap/_global.js';

init()

barba.hooks.enter((data) => {
    init(data);
});

function init(data = '') {
    const 
        onboard = barba__nextQS('.onboard', data);
        if(!onboard) return;

    const startBtn = onboard.querySelector('.onboard__start')

    initSwiper(onboard);
    setCookie(onboard);
    gsapGlobal.fadeUp(onboard.querySelector('.card'), 1)

    function initSwiper(section) {
        const
            carousel = section.querySelector('.swiper'),
            options = {
                grabCursor: false,
                loop: false,
                threshold: 10,
                draggable: false,
                pagination: {
                    el: '.swiper-pagination',
                        type: 'fraction',
                },
            },
            prevBtn = section.querySelector('.swiper-button-prev'),
            nextBtn = section.querySelector('.swiper-button-next')
        
        carousel.swiper = new Swiper(carousel, options);
        
        carousel.swiper.on('reachEnd', function () {
            gsapGlobal.fadeUp(startBtn, 0.5)
        });

        prevBtn.onclick = e => {
            carousel.swiper.slidePrev();
        }

        nextBtn.onclick = e => {
            carousel.swiper.slideNext();
        }
    }

    function setCookie(section) {
        const 
            links = onboard.querySelectorAll('a'),
            options = {
                domain: window.location.host,
            }

        links.forEach(element => {
            element.addEventListener('click', ()=> {
                Cookies.set('viewed_onboarding', 'yes', options)
            });
        });

    }
}

