import {barba__nextQSA} from '../_barba/_util.js';

init()

barba.hooks.enter((data) => {
    init(data);
});

function init(data = '') {
    const accordions = barba__nextQSA('.accordion', data);
        
    accordions.forEach(accordion => {
        const 
            // items = accordion.querySelectorAll('.accordion__item'),
            inputs = accordion.querySelectorAll('.accordion input') 

        // if(content) gsap.set(content, {display: 'none'});

        inputs.forEach((input, i) => {
            let 
                tl = gsap.timeline({paused: true}),
                content = input.nextElementSibling ? input.nextElementSibling.querySelector('label + *') : false;

                

                if(content) {
                    gsap.set(content, {height: 0, display: 'block', overflow: 'hidden'})
                    // tl.set(content, {height: 0, display: 'block', overflow: 'hidden'});
                    tl.to(content, {height: 'auto'})
                }
            


            input.animation = tl;
            input.onchange = ()=> {
                if(content) {
                    input.checked ? input.animation.play() : input.animation.reverse();
                }
            };
        });


        // inputs.forEach((input, i) => {
        //     const tl = gsap.timeline({paused: true})

        //     if(content[i]) {
        //         tl.set(content[i], {height: 0, display: 'block', overflow: 'hidden'})
        //         tl.to(content[i], {height: 'auto'})
        //         input.animation = tl;
        //         input.onchange = ()=> {
        //             input.checked ?  input.animation.play() : input.animation.reverse();
        //         }
        //     }
        // });
    })
}