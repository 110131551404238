import {barba__scrollPosition} from '../_barba/_util.js';

export function transitions(container) {
    const arr = [
        transitionDefault(),
        transitionRefreshTo(),
        transitionRefreshFrom(),
    ];

    return arr;

    function transitionDefault() {
        const obj = {};

        obj.name = 'default';

        // create html
        const element = document.createElement('div');
        element.classList.add('barba__transition', 'barba__transition--'+obj.name);
        container.appendChild(element);

        obj.leave = function(data) {
            const done = this.async();
            const tl = gsap.timeline({
                onComplete: ()=> {
                    done();
                }
            });

            tl
                .set(element, {xPercent: -100, display: 'block'})
                .to(data.current.container, { autoAlpha: 0, })
                .to(element, {xPercent: 0,}, '-=0.3')
        }

        obj.beforeEnter = function(data) {
            const done = this.async();
            const tl = gsap.timeline({
                onStart: ()=> {
                    done();
                }
            });

            tl
                .add( () => { barba__scrollPosition(data) } )
                .set(data.next.container, { autoAlpha: 0, })
                .to(element, {xPercent: 100,})
                .to(data.next.container, { autoAlpha: 1, }, '=-0.3')
                .set(element, {display: 'none'})
        }
    
        return obj;
    }

    // refresh
    function transitionRefreshTo() {
        const 
            obj = {},
            element = document.querySelector('.barba__transition--default');

            obj.name = 'refreshTo';

        obj.leave = function(data) {
            const done = this.async();
            const tl = gsap.timeline({
                onComplete: ()=> {
                    done();
                },
                onStart: ()=> {
                    window.location.href = data.next.url.href;
                }
            });

            tl
                .set(element, {xPercent: -100, display: 'block'})
                .to(data.current.container, { autoAlpha: 0, })
                .to(element, {xPercent: 0,}, '-=0.3')

        }

        obj.to = { namespace: ['login', 'bios wordsearch', 'faqs', 'profile', 'pledge-gallery', 'video'] }

        return obj;
    }

    function transitionRefreshFrom() {
        const 
        obj = {},
        element = document.querySelector('.barba__transition--default');

        obj.name = 'refreshFrom';

        obj.leave = function(data) {
            const done = this.async();
            const tl = gsap.timeline({
                onComplete: ()=> {
                    done();
                },
                onStart: ()=> {
                    window.location.href = data.next.url.href;
                }
            });

            tl
                .set(element, {xPercent: -100, display: 'block'})
                .to(data.current.container, { autoAlpha: 0, })
                .to(element, {xPercent: 0,}, '-=0.3')

        }

        obj.from = {namespace: ['video',] }

        return obj;

    }
}