export function initLikes() {
    const btn = document.querySelectorAll('.thumbs-rating-container .thumbs-rating-up');
    btn.forEach((ele)=>{
        ele.addEventListener('click', ()=> {
            let prev = ele.previousElementSibling;
            if(window.getComputedStyle(prev)['display'] !== 'inline-block') {
                const 
                    parent = ele.closest('[data-id]'),
                    data = parent ? parent.dataset : false,
                    items = parent ? document.querySelectorAll('[data-id="' + data.id + '"]') : [];

                items.forEach(ele =>{
                    let 
                        thumb = ele.querySelector('.thumbs-rating-up'),
                        value = parseInt(thumb.innerText.slice(1, thumb.innerText.length-1)),
                        newVal = (value + 1)

                        thumb.innerText = '(' + newVal + ')';
                })
            }
        });
        
    });
}