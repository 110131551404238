import {barba__nextQSA} from '../_barba/_util.js';

init()

barba.hooks.enter((data) => {
    init(data);
});

function init(data) {
    const links = barba__nextQSA('.js__go-back', data);

    links.forEach((element, i) => {
        element.setAttribute('href', 'javascript:history.go(-1)');
    })
}