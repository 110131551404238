export function hooks() {
    barba.hooks.beforeEnter((data) => {
        setBodyClass(data);
    });
}

function setBodyClass(data) {
    const html = new DOMParser().parseFromString(data.next.html, 'text/html');
    const classNames = html.body.className
    const body = document.body;

    body.setAttribute('class', classNames);
}
