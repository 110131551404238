import {barba__nextQS} from '../_barba/_util.js';

init()

barba.hooks.enter((data) => {
    init(data);
});

function init(data) {
    let section = barba__nextQS('.event-day', data);
    if(!section) return;

    const 
        videos = section.querySelectorAll('.event-day__bg video'),
        svgPoints = section.querySelectorAll('.event-day__bg svg path, .event-day__bg svg .group__btn'),
        idPrefix = 'event-info_'

    // handle video
    videos.forEach((video, i) => {
        video.play();

        if(!video.loop) {
            video.onended = ()=> { introVideoEnd(i) }
        }
        return;
    })

    svgPoints.forEach((point) => {
        const
            group = point.closest('g'),
            overlay = document.getElementById(idPrefix + group.dataset.link),
            path = group.querySelector('path'),
            btn = group.querySelector('.btn'),
            text = group.querySelector('.text-hotspot');

        // handles hover on points
        point.addEventListener('mouseenter', e => {
            path.classList.add('active')
            btn.classList.add('active')
            if(text) text.classList.add('active')
           
        })
        point.addEventListener('mouseleave', e => {
            path.classList.remove('active')
            btn.classList.remove('active')
            if(text) text.classList.remove('active')
        })

        // handles click on points
        point.addEventListener('click', e => {
            if(overlay.classList.contains('preview-menu')) {
                group.classList.toggle('active');
                return;
            }

            overlay.animation.play()
        })
    })

    function introVideoEnd(i){
        const currentVideo = videos[i];
        const content = section.querySelectorAll('[data-hide]');
        const tl = gsap.timeline();

        tl.to(content, { autoAlpha: 1, stagger: 0.3});
        tl.set(currentVideo, { autoAlpha: 0});
        
        return tl;
    }

}