import {barba__nextQSA} from '../_barba/_util.js';

init()

barba.hooks.enter((data) => {
    init(data);
});

function init(data) {
    const filter = barba__nextQSA('.filter', data)
    if(!filter.length) return;

    filter.forEach((element) => {
        const nav =  element.querySelector('.filter__nav')
        if(nav) {
            let 
                navInput = nav.querySelectorAll('input'),
                navClear = nav.querySelector('a'),
                grid = element.querySelector('.masonry__grid'),
                items = element.querySelectorAll('[data-filter]')

            navClear.onclick = e => {
                e.preventDefault();
                const checkedEle = nav.querySelector('input:checked')  
    
                if(checkedEle) checkedEle.checked = false;
                updateFilterContent();
            }
    
            navInput.forEach(ele =>{
                ele.onchange = e => {
                    updateFilterContent(ele.id);
                }
            })

            function updateFilterContent(id=false) {
                const
                    tl = gsap.timeline(),
                    filterEle = id ? grid.querySelectorAll('[data-filter="'+ id +'"]') : items;
    
                tl
                    .set(items, { display: 'none'})
                    .set(filterEle, { display: 'block'})
                    .add( ()=> { grid.macyInstance.reInit() })
    
            }
        }

    })
    
}
