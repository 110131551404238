import * as query from './_query.js';

export function initSwiper(section) {
    const
        carousel = section.querySelector('.gallery__carousel .swiper'),
        options = {
            modules: [Panorama],
            effect: 'panorama',
            grabCursor: true,
            loop: true,
            loopAdditionalSlides: 2,
            threshold: 10,
            // lazy load
            lazy: {
                loadPrevNext: true,
                loadPrevNextAmount: 3,
            },
            pagination: {
                el: '.swiper-pagination',
                type: 'custom',
                renderCustom: (swiper, current, total) => {
                    let ele = swiper.pagination.el;
                    ele.innerText = (current-1) + ' / ' + (total-1);
                }
            },
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            initialSlide: startSlide(),
  
        }

    carousel.swiper = new Swiper(carousel, options);

    carousel.swiper.on('slideChange', function (swiper) {
        query.update(swiper)
    });
      

    

    function startSlide() {
        const url = new URL(window.location.href);
        const search = new URLSearchParams(url.search);
        const id = search.has('id') && search.get('id') !== '0' ? search.get('id') : false;
        
        let index = 0;
       
        if(id) {
            let slide = document.querySelector('.gallery__slide[data-id="' + id + '"]');
            if(slide) index = parseInt(slide.dataset.index);
        }
        return index;
    }
    
}

function Panorama({ swiper, extendParams, on }) {
    extendParams({
        panorama: {
        depth: 0,
        rotate: 30,
        },
    });

    on('beforeInit', () => {
        if (swiper.params.effect !== 'panorama') return;
        swiper.classNames.push(`${swiper.params.containerModifierClass}panorama`);
        swiper.classNames.push(`${swiper.params.containerModifierClass}3d`);
        const overwriteParams = {
        watchSlidesProgress: true,
        };
        Object.assign(swiper.params, overwriteParams);
        Object.assign(swiper.originalParams, overwriteParams);
    });

    on('progress', () => {
        if (swiper.params.effect !== 'panorama') return;
        const sizesGrid = swiper.slidesSizesGrid;
        const { depth = 200, rotate = 30 } = swiper.params.panorama;
        const angleRad = (rotate * Math.PI) / 180;
        const halfAngleRad = angleRad / 2;
        const angleModifier = 1 / (180 / rotate);

        for (let i = 0; i < swiper.slides.length; i += 1) {
            const slideEl = swiper.slides[i];
            const slideProgress = slideEl.progress;
            const slideSize = sizesGrid[i];
            const progressModifier = swiper.params.centeredSlides
                ? 0
                : (swiper.params.slidesPerView - 1) * 0.5;
            const modifiedProgress = slideProgress + progressModifier;
            const angleCos = 1 - Math.cos(modifiedProgress * angleModifier * Math.PI);
            const translateX = `${modifiedProgress * (slideSize / 3) * angleCos}px`;
            const rotateY = modifiedProgress * rotate;
            const radius = (slideSize * 0.5) / Math.sin(halfAngleRad);
            const translateZ = `${radius * angleCos - depth}px`;
            // slideEl.style.transform = `translateX(${translateX}) translateZ(${translateZ}) rotateY(${rotateY}deg)`;

            gsap.set(slideEl, {translateX: translateX, translateZ: translateZ, rotateY: rotateY});
            // gsap.set(slideEl, {translateX: translateX, translateZ: 0, scale: (radius * angleCos - 0.86), rotateY: rotateY});


        }
    });

    on('setTransition', (s, duration) => {
        if (swiper.params.effect !== 'panorama') return;
        swiper.slides.forEach((slideEl) => {
        // eslint-disable-next-line
        slideEl.style.transition = `${duration}ms`;
        });
    });
}