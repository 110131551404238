export function animation() {
    const 
        tl = gsap.timeline({ 
            paused:1,
            onStart: ()=> {
                document.body.classList.add('nav--active')
            },
            onReverseComplete: ()=> {
                document.body.classList.remove('nav--active')
            },
        });
    
    tl
        .set('html', { overflow: 'hidden' })
        .add( anim__input(), 0 )
        .add( anim__inner(), 0 )

    return tl;
}

function anim__input() {
    const 
        tl = gsap.timeline(),
        ele = document.querySelector('.menu-toggle__inner')

    tl  
        .to(ele, { rotateY: -180 }, 0)

    return tl;
}

function anim__inner() {
    const 
        tl = gsap.timeline(),
        nav = document.querySelector('.nav'),
        inner = document.querySelector('.nav__inner')

    gsap.set(nav, { display: 'none'})
    gsap.set(inner, { xPercent: -100})

    tl
        .set(nav, { display: 'block'})
        .to(inner, { xPercent: 0})
        
    return tl;
}