import {barba__nextQS, barba__nextQSA} from '../_barba/_util.js';
import {initCarousel} from './_carousel.js';
import {initSwiper} from './_swiper.js';
import {initLikes} from './_likes.js';
import {initKeys} from './_keys.js';
import {initSubmit} from './_submit.js';

init()

barba.hooks.enter((data) => {
    init(data);
});

function init(data = '') {
    const 
        gallery = barba__nextQS('.gallery', data),
        slides = barba__nextQSA('.gallery__slide', data)
    if(!gallery || slides.length < 2) return;

    initSwiper(gallery);
    initCarousel(gallery);
    initLikes()
    initKeys(gallery);
    initSubmit();
}