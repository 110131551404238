const match = {
    pin: [2,0,2,3],
    domain: 'pfizer.com',
}

init();

function init(){
    const form = document.querySelector('form#login')
    if(!form) return;

    const 
        email = form.querySelector('#login__email'),
        pin = form.querySelectorAll('.form__pin input'),
        submit = form.querySelector('#login__submit');

    checkform();
    
    email.addEventListener('input', emailValid);
    pin.forEach((ele, index) => {
        ele.addEventListener('input', pinValid)
    })

    function emailValid() {
        const 
            parent = this.closest('.form__input'),
            regEx = /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
            val = this.value
    
        this.isValid = false;
        parent.classList.remove('valid')
        
        if(regEx.test(val) && val.toLowerCase().includes(match.domain)) {
            this.isValid = true;
            parent.classList.add('valid')
        }

        checkform();
    }
    
    function pinValid() {
        const 
            val = this.value;

        if(isNaN(val)) {
            this.value = '';
            return false;
        }

        if(this.nextElementSibling && this.value !== '') this.nextElementSibling.focus();

        checkform();
    }

    function checkform() {
        const validEmail = email.isValid;
        let pinArr = [];
        pin.forEach((ele, index) => {
            pinArr.push(parseInt(ele.value));
        })

        submit.setAttribute('disabled', '')

        if(validEmail && pinArr.toString() === match.pin.toString()) {
            submit.removeAttribute('disabled')
            submit.focus();
        }

    }
}

