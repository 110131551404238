import {barba__nextQSA} from '../_barba/_util.js';

init()

barba.hooks.enter((data) => {
    init(data);
});

function init(data) {
    const 
        section = barba__nextQSA('.preview', data),
        miniMenu = barba__nextQSA('.preview-menu', data);

    section.forEach(ele => {
        const 
            inner = ele.querySelector('.preview__inner'),
            id = ele.id,
            close = ele.querySelector('.preview__close')

        gsap.set(ele, { display: 'none', opacity: 0});
        gsap.set(inner, { xPercent: -100});

        var tl = gsap.timeline({ 
            paused:true,
            onStart: function() { window.location.hash = id; },
            // remove hash
            onReverseComplete: function() { history.pushState('', document.title, window.location.pathname + window.location.search); }
        });
            tl.set('html', { overflow: 'hidden' })
            tl.set(ele, { display: 'block' })
            tl.to(ele, { opacity: 1 }, 0)
            tl.to(inner, { xPercent: 0 }, 0)

            ele.animation = tl;

        // close the animation
        close.addEventListener('click', e => {
            e.preventDefault();
            ele.animation.reverse();
        });
    })

    miniMenu.forEach(ele => {
        const 
            id = ele.id.replace('event-info_', ''),
            html = ele.innerHTML,
            svgMenu = document.querySelector('.event-day__bg svg [data-link="'+ id +'"] .group__menu')

        svgMenu.innerHTML = html
    })
}