export function initSubmit() {
    const form = document.querySelector('.gallery__form-overlay form');
    if(form == null) return;

    let toCheck = form.querySelectorAll('#uploadFile, [type="text"], #w3review') 
    let submit = form.querySelector('[type="submit"]');

    submit.addEventListener('click', e=> {
        let valid = 1;
        toCheck.forEach((ele)=>{
            if(!ele.checkValidity()) return valid = 0
        })

        if(valid) submit.setAttribute('disabled', '')

        form.submit()
    });

    

    
}