import {barba__nextQSA} from '../_barba/_util.js';

init()

barba.hooks.enter((data) => {
    init(data);
});

function init(data = '') {
    const btnDefault = barba__nextQSA('.btn:not(input):not(.btn--hotspot)', data)
    const btnHotSpot = barba__nextQSA('.btn--hotspot', data)
    let anim;

    btnDefault.forEach((btn)=> {
        const span = document.createElement('span');
        btn.append(span)
        gsap.set(span, { width: 0 });

        btn.addEventListener('mouseenter', e => {
            btnEnter(span);
        })
        btn.addEventListener('mouseleave', e => {
            btnExit();
        })
    })

    btnHotSpot.forEach((btn)=> {
        const text = btn.textContent.replace(' ', '');
        const span = document.createElement('span');

        btn.innerHTML = btn.innerHTML.replace(text, '');
        span.innerHTML = text.replace(/(<([^>]+)>)/gi, '').replace(' ', '');
        btn.append(span)
    });


    function btnEnter(ele) {
        if (anim && anim.isActive()) {
            anim.progress(0);
        }
        // create needed timeline for hover
        anim = gsap.timeline();
        anim.to(ele, {
            duration: 0.3,
            width: '100%'
        });
    }
    function btnExit() {
        anim.reverse();
    }
}