import {animation} from './_animation.js';

init();

function init() {
    const 
        input = document.getElementById('menu_toggle')

        input.animation = animation();

        input.onchange = function(){
            if(!this.hasOwnProperty('animation')) return;

            this.checked ? this.animation.play(): this.animation.reverse();
        };
    
    barba.hooks.beforeEnter((data) => {        
        input.animation.seek(0).pause()
        input.checked = false;
        gsap.set('html', { overflow: 'auto'})
    });
}