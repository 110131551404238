
export function fadeUp(ele, delay=0) {
    const tl = gsap.timeline();
    tl
        .set(ele, { y: 50})
        .to(ele, {y: 0, autoAlpha: 1, stagger: 0.2}, delay)

    return tl;
}

// export function fadeUpChildren(parent, delay=0) {
//     const tl = gsap.timeline();

//     if(parent.children.length) {
//         tl.set(parent.children, { y: 50, autoAlpha: 0})
//     }
//     tl.set(parent.children, { y: 50, autoAlpha: 0})
//     console.log(parent.children)
//     // tl
//     //     .set(ele, { y: 50})
//     //     .to(ele, {y: 0, autoAlpha: 1, stagger: 0.2}, delay)

//     return tl;
// }