import {barba__nextQS} from '../_barba/_util.js';
import * as gsapGlobal from '../_gsap/_global.js';

init()

barba.hooks.enter((data) => {
    init(data);
});

function init(data = '') {
    const section = barba__nextQS('.experience', data);
    if(!section) return;

    initBackground(section);

    function initBackground(section) {
        const container = section.querySelector('.experience__bg')
        if(container === null) return

        const
            introVideo = container.querySelector('video:not([loop])'),
            loopVideo =  container.querySelector('video[loop]'),
            image = container.querySelector('img');

        

        day__CTAs(section)
        day__Agenda(section)

        if(introVideo) {
            gsap.set(introVideo, { autoAlpha: 1})
            introVideo.play();
            introVideo.onended = e => { postIntro() };
           
            return;
        }

        postIntro();

        function postIntro() {
            if(loopVideo) loopVideo.play();
            gsap.to(loopVideo ? loopVideo : image, { autoAlpha: 1})

            animContent(section)
            bgMovement(section)
        }
    }

    function animContent(section) {
        const contentEle = section.querySelectorAll('.experience__col:nth-of-type(1), .experience__cta, .tool-tip')
        gsapGlobal.fadeUp(contentEle, 2);
    }

    function bgMovement(section) {
        let 
            container = section.querySelector('.experience__bg'),
            inner = section.querySelector('.experience__bg-inner'),
            diff = (inner.offsetWidth - container.offsetWidth) / 2,
            viewport = {
                x: window.innerWidth/2,
                y: window.innerHeight/2,
            };

        window.bgMovement = e => { 
            gsap.to(inner, 1, mouseMoveBg(e))

        };

        function mouseMoveBg(e) {
            let obj = {
                x : parseFloat(diff * (e.clientX - viewport.x) / viewport.x).toFixed(2) * -1,
                y : parseFloat(diff * (e.clientY - viewport.y) / viewport.y).toFixed(2) * -1,
            }
            return obj;
        }
        
        barba.hooks.before((data) => { window.bgMovement = false });
        window.addEventListener('mousemove', window.bgMovement);
    }

    function day__CTAs(section) {
        if(!section.classList.contains('experience--day')) return;

        const 
            input = section.querySelector('.experience__col-input'),
            tl = gsap.timeline({paused: 1}),
            col = section.querySelector('.experience__col:nth-of-type(2)'),
            ctawrap = section.querySelector('.experience__cta-wrap'),
            cta = section.querySelectorAll('.experience__cta')

        gsap.set(col, { flexGrow: 0, width: 0, flex: 'unset', maxWidth: '50%'})
        gsap.set(cta, { scale: 0, autoAlpha: 0,})
            
        tl
            .addLabel('Start')
            .set(ctawrap, { overflow: 'visible', width: '0%'})
            .set(cta, { x: -500, autoAlpha: 0, scale: 0})
            .to(col, { flexGrow: 1, width: 'auto', flex: '0 0 50%'}, 0)
            .to(ctawrap, 0.2, { width: '100%'}, 0)
            .to(cta, {autoAlpha: 1, scale: 1, stagger: 0.2, }, 0.1)
            .to(cta, {x: 0, stagger: 0.1, ease: 'back.out(2)'}, 0.1)
            .addLabel('Middle')
            .to(cta, {autoAlpha: 0, scale: 0, x: -200 }, 'Middle')
            .to(col, { flexGrow: 0, width: 0, flex: '0 0 0%'}, 'Middle')
            .addLabel('End')

        input.onchange = ()=> {
            input.checked ? tl.seek('Start').tweenTo('Middle') : tl.seek('Middle').tweenTo('End');
        }
    }

    function day__Agenda(section) {
        if(!section.classList.contains('experience--day')) return;

        const input = section.querySelector('#agenda')

        input.onchange = ()=> {
            input.checked ? window.location.hash = input.id : history.replaceState(null, null, ' ');
        }

        if(window.location.hash === '#agenda') input.checked = true;
        
    }
}

